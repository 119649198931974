<template>
  <sdPageHeader title="Customer"> </sdPageHeader>
  <Main>
    <sdCards headless>
      <a-form
        :hideRequiredMark="false"
        ref="formRef"
        :model="form"
        :rules="formRules"
      >
        <a-row :gutter="25">
          <a-col :lg="12" :xs="24">
            <a-form-item name="first_name" label="First Name">
              <a-input
                v-model:value="form.first_name"
                placeholder="Enter first name"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :xs="24">
            <a-form-item name="last_name" label="Last Name">
              <a-input
                v-model:value="form.last_name"
                placeholder="Enter Price"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :xs="24">
            <a-form-item name="company_name" label="Company Name">
              <a-input
                v-model:value="form.company_name"
                placeholder="Enter Company Name"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :xs="24">
            <a-form-item name="company_no" label="Company No">
              <a-input
                v-model:value="form.company_no"
                placeholder="Enter Company No"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :xs="24">
            <a-form-item name="address" label="Address">
              <a-input
                v-model:value="form.address"
                placeholder="Enter Address"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :xs="24">
            <a-form-item name="city" label="City">
              <a-input v-model:value="form.city" placeholder="Enter City" />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :xs="24">
            <a-form-item name="zip_code" label="Zip Code">
              <a-input
                v-model:value="form.zip_code"
                placeholder="Enter Zip Code"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :xs="24">
            <a-form-item name="website" label="Website">
              <a-input
                v-model:value="form.website"
                placeholder="Enter Website"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :xs="24">
            <a-form-item name="support_email" label="Support Email">
              <a-input
                v-model:value="form.support_email"
                placeholder="Enter Support Email"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :xs="24">
            <a-form-item name="login_block" label="Block access">
              <a-switch v-model:checked="form.login_block" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="25">
          <a-col :lg="12" :xs="24">
            <a-form-item name="password" label="Password">
              <a-input
                v-model:value="form.password"
                placeholder="Enter Password"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :xs="24">
            <a-form-item name="confirm_password" label="Confirm Password">
              <a-input
                v-model:value="form.confirm_password"
                placeholder="Enter Confirm Password"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="24" :xs="24">
            <sdButton @click="onSubmitPress" type="primary" class="pull-right">
              Save
            </sdButton>
          </a-col>
        </a-row>
      </a-form>
    </sdCards>
  </Main>
</template>
<script>
//import _ from "lodash";
import { API } from "@/services/api";
import { Main } from "../../styled";
import Notification from "@/services/Notification";

export default {
  name: "setupParticipant",
  components: { Main },
  computed: {
    countries: function () {
      return this.$store.getters.countries;
    },
    formRef: function () {
      return this.$refs.formRef;
    },
  },
  data() {
    return {
      form: {
        first_name: "",
        last_name: "",
        company_name: "",
        company_no: "",
        address: "",
        city: "",
        zip_code: "",
        website: "",
        support_email: "",
        password: "",
        confirm_password: "",
        login_block: false,
      },
      customer_id: 0,
      formRules: {
        first_name: [
          {
            required: true,
            trigger: "blur",
            message: "Please enter first name",
          },
        ],
        last_name: [
          {
            required: true,
            trigger: "blur",
            message: "Please enter last name",
          },
        ],
        company_name: [
          {
            required: true,
            trigger: "blur",
            message: "Please enter company name",
          },
        ],
        company_no: [
          {
            required: true,
            trigger: "blur",
            message: "Please enter company no",
          },
        ],
        address: [
          {
            required: true,
            trigger: "blur",
            message: "Please enter address",
          },
        ],
        city: [
          {
            required: true,
            trigger: "blur",
            message: "Please enter city",
          },
        ],
        zip_code: [
          {
            required: true,
            trigger: "blur",
            message: "Please enter zip code",
          },
          {
            type: "number",
            trigger: "blur",
            transform: (val) => Number(val),
            message: "Please enter correct zip code",
          },
        ],
      },
    };
  },
  methods: {
    async onSubmitPress() {
      if (this.form.password != this.form.confirm_password) {
        Notification.show("Please enter valid password", {
          type: "info",
          title: "Password issue!",
        });
        return null;
      }
      try {
        await this.formRef.validate();
        const fData = new FormData();
        for (const [key, value] of Object.entries(this.form)) {
          if (key) {
            if (key == "login_block") {
              fData.append(key, value ? 1 : 0);
            } else {
              fData.append(key, value);
            }
          }
        }
        fData.append("id", this.customer_id);

        const dataRes = await API.commitCustomer(fData);
        const { message, status } = dataRes.data;

        if (message) {
          Notification.show(message, {
            type: status ? "success" : "error",
            title: status ? "Success" : "Error",
          });
        }
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, { type: "error", title: "Error" });
        }
      }
    },
    async getCustomerDetails() {
      const { customer_id = "" } = this.$route.params;
      if (!customer_id) {
        return null;
      }
      this.customer_id = customer_id;
      try {
        const res = await API.getCustomerDetail({
          params: {
            id: customer_id,
          },
        });

        const { data, status } = res?.data ?? {};
        if (status) {
          const {
            first_name = "",
            last_name,
            email,
            company_name,
            company_no,
            address,
            city,
            zip_code,
            website,
            support_email,
            login_block,
          } = data;

          this.form = {
            ...this.form,
            first_name,
            last_name,
            email,
            company_name,
            company_no,
            address,
            city,
            zip_code,
            website,
            support_email,
            login_block: !!login_block,
          };
        }
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, { type: "error", title: "Error" });
        }
      } finally {
        this.fetching = false;
      }
    },
  },
  created() {
    this.getCustomerDetails();
  },
};
</script>
